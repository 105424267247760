.nav-side-menu {
    overflow: auto;
    background-color: $cor-primaria_back;
    position: fixed;
    top: 50px;
    width: 350px;
    height: 100%;
    color: $cor-padrao;
    z-index: 300;
    display: none;
    
    #menus-nav {
        margin-bottom:200px;
        
    }

    @media #{$mq-xs-max} {
        position: fixed;
        height: auto;
        max-height: 86vh;
        top: 50px;
        width: 100%;
        margin-bottom: 10px;
        display: block !important;
        padding-bottom: 0;
    }

    .brand {
        background-color: darken($cor-primaria, 10%);
        line-height: 42px;
        display: block;
        text-align: left;
        font-size: 16px;
        font-weight: 700;

        a {
            color: $cor-padrao;
        }
    }

    .toggle-btn {
        display: none;

        @media #{$mq-xs-max} {
            position: absolute;
            display: block;
            cursor: pointer;
            right: 10px;
            top: 10px;
            z-index: 10;
            padding: 3px;
            background-color: $cor-padrao;
            color: #000;
            width: 40px;
            text-align: center;
        }
    }

    ul, li {
        list-style: none;
        padding: 0px;
        margin: 0px;
        line-height: 35px;
        cursor: pointer;

        .sub-menu li {
            border: none;
            line-height: 28px;
            margin-left: 0px;
        }

    }

    ul {

        li {

            -webkit-transition: all 0.6s ease;
            -moz-transition: all 0.6s ease;
            -ms-transition: all 0.6s ease;
            -o-transition: all 0.6s ease;
            transition: all 0.6s ease;

            &:before {
                display: inline-block;
                padding-left: 10px;
                padding-right: 10px;
                vertical-align: middle;
            }

            a {
                width: 100%;
                text-decoration: none;
                color: $cor-padrao;
                display: block;

                i {
                    width: 20px;
                    padding-left: 10px;
                    padding-right: 20px;
                }
            }

            &:hover {
                @include menu-hover();
            }
        }

        .sub-menu {

            &.n-1 {
                li {
                    padding-left: 20px;
                    background-color: lighten($cor-primaria, 5%);

                    i {
                        padding-left: 0;
                    }
                }
            }

            &.n-2 {
                li {
                    padding-left: 40px;
                    background-color: lighten($cor-primaria, 10%);

                    i {
                        padding-left: 0;
                    }
                }
            }

            &.n-3 {
                li {
                    padding-left: 60px;
                    background-color: lighten($cor-primaria, 10%);

                    i {
                        padding-left: 0;
                    }
                }
            }

            &.n-4 {
                li {
                    padding-left: 80px;
                    background-color: lighten($cor-primaria, 10%);

                    i {
                        padding-left: 0;
                    }
                }
            }

            li {
                -webkit-transition: all 0.6s ease;
                -moz-transition: all 0.6s ease;
                -ms-transition: all 0.6s ease;
                -o-transition: all 0.6s ease;
                transition: all 0.6s ease;

                &:hover {
                    @include menu-hover();
                }
            }
        }
    }

    .tem-sub {
        font-weight: 700;

        a {
            color: lighten($cor-primaria, 70%);
        }

        &.collapsed:before {
            float: right;
            font-family: FontAwesome;
            content: "\f0ab";
        }

        &:before {
            float: right;
            font-family: FontAwesome;
            content: "\f0aa";
        }
    }
}


.nav-side-menu>.brand {
    @media #{$mq-xs-max} {
        text-align: left;
        font-size: 14px;
        padding-left: 20px;
        line-height: 35px;
        background-color: $cor-secundaria;
        height: 51px;
        overflow: hidden;

        .brand-breadcrumbs {
            line-height:51px;
            overflow: overlay;
            height: 80px;

            .breadcrumb {
                padding:0;
                margin:0;
                background:none;
                border-radius:0;
                white-space:nowrap;

                .active {
                    color:white;
                    font-size: 13px;
                }

                li:last-child {
                    margin-right: 150px;
                }
            }
        }
    }
}

.menu-normal{
    @media #{$mq-xs-max} {
        display: none;
    }
}

.nav-side-menu .menu-list .menu-content {
    @media #{$mq-sm-min} {
        display: block;
    }
}
