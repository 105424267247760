#header {
	position: fixed;
	width: 100%;
	height: 50px;
	background-color: $cor-primaria_back;
	// box-shadow: 1px 1px 10px 1px darken($cor-secundaria, 5);
	z-index: 200;

	#box-menu {
		position: relative;
		background-color: $cor-secundaria;
		margin: 0;
		padding: 0;
		text-align: center;

		i {
			// padding: 0.5 * $font-size;
			padding: 0;
			line-height: 50px;
			height: 50px;
			font-size: 2 * $font-size;
			color: $cor-padrao;
			display: block;
			cursor: pointer;

			&:hover {
				color: $cor-primaria;
			}
		}
	}

	.logo-aplicativo-header {
		height: 70px;
		margin: 7px;
		border-left-width: thin;
    	border-left-color: white;
    	border-left-style: groove;

		@media #{$mq-sm-max} {
			margin: 7px 3px;
		}
	}

	.logo-empresa-header-link {
		height:50px;
		display: inline-block;
	}

	.logo-empresa-header {
		//height: 44px;
		height: 50px;
		//margin: 20px 10px;
		margin-right: 15px;

	}

	ol.breadcrumb {
		list-style: none;
		margin: 3px;
		padding: $font-size;
		border-radius: 5px;
		// box-shadow: inset 1px 1px 8px darken($cor-primaria, 5);
		background-color: transparent;
		font-size: 0.8 * $font-size;


		li {
			display: inline;
			color: $cor-padrao;

			// &::after {
			// 	content: '/';
			// 	padding: 0 $font-size;

			// 	@media #{$mq-sm-max} {
			// 		padding: 0 0.7 * $font-size;
			// 	}
			// }

			a {
				color: $cor-padrao;
			}

			&:last-child {
				color: #aaa;

				&::after {
					content: '';
					padding: 0;
				}
			}
		}
	}

	.menu-icone-header {
		list-style: none;
		margin: 0px;
		// padding: 0.8 * $font-size 0.3 * $font-size;

		li {
			display: inline-block;
			width:50px;
			text-align: center;
			padding: 0 0;
			margin-left: -4px;

			a, i {
				color: $cor-padrao;
			}

			i {
				font-size: 2 * $font-size;
				cursor: pointer;
				padding: 0.8*$font-size 0;
				width:100%;

				&:hover {
					// color: $cor-secundaria;
					// transform: scale(1.4);
					background: rgba(0,0,0,0.4);
				}
			}
		}
	}

}

.barra-pesquisa {
	position: absolute;
	top: 50px;
	width: 100%;
	height: 40px;
	background-color: $cor-secundaria;
	display: none;
	z-index: 500;

	.sem-padding {
		padding: 0;
	}

	input {
		width: 100%;
		height: 40px;
		font-size: 1.3 * $font-size;
		text-align: center;
		border: none;
		outline: none;
		background-color: $cor-secundaria;
	}::-webkit-input-placeholder {
		color: #333;
	}
	:-moz-placeholder {
		color: #333;
	}
	::-moz-placeholder {
		color: #333;
	}
	:-ms-input-placeholder {
		color: #333;
	}

	.btn-fechar {
		width: 100%;
		height: 40px;
		margin: 0;
		padding: 0;
		background-color: #C9302C;
		border: none;
		color: $cor-padrao;
		font-size: 2 * $font-size;

		i {
			line-height: 40px;
		}
	}
}

.menu-plus {
	margin: 17px 0;
	cursor: pointer;
	display: block;
	color: $cor-padrao;

	i {
		font-size: 3 * $font-size;
	}

	&:hover {
		color: $cor-secundaria;
	}
}
