/* 
 * treeGrid.css
 *
 * Styles for the tree-grid
 * for both Bootstrap 2 and Bootstrap 3
 *
 */
.tree-grid-animate-enter,
.tree-grid-row.ng-enter {
    -moz-transition: 200ms linear all;
    -o-transition: 200ms linear all;
    -webkit-transition: 200ms linear all;
    transition: 200ms linear all;
    position: relative;
    display: block;
    opacity: 0;
    max-height: 0;
}

.tree-grid-animate-enter.tree-grid-animate-enter-active,
.tree-grid-row.ng-enter-active {
	opacity    : 1;
	max-height : 30px;
}

.tree-grid-animate-leave,
.tree-grid-row.ng-leave {
    -moz-transition: 200ms linear all;
	-o-transition: 200ms linear all;
	-webkit-transition: 200ms linear all;
    transition: 200ms linear all;
    position: relative;
    display: block;
    height: 30px;
    max-height: 30px;
    opacity: 1;
}

.tree-grid-animate-leave.tree-grid-animate-leave-active,
.tree-grid-row.ng-leave-active {
	height     : 0;
	max-height : 0;
	opacity    : 0;
}

/*
 * Angular 1.2.0 Animation placeholders.
 */
.tree-grid-animate.ng-enter {
}

/*
 * Normal CSS styles.
 */
.tree-grid i.indented {
	padding : 2px;
}

.tree-grid .level-1 .indented {
	position : relative;
	left     : 0;
}

.tree-grid .level-2 .indented {
	position : relative;
	left     : 20px;
}

.tree-grid .level-3 .indented {
	position : relative;
	left     : 40px;
}

.tree-grid .level-4 .indented {
	position : relative;
	left     : 60px;
}

.tree-grid .level-5 .indented {
	position : relative;
	left     : 80px;
}

.tree-grid .level-6 .indented {
	position : relative;
	left     : 100px;
}

.tree-grid .level-7 .indented {
	position : relative;
	left     : 120px;
}

.tree-grid .level-8 .indented {
	position : relative;
	left     : 140px;
}

.tree-grid .level-9 .indented {
	position : relative;
	left     : 160px;
}

.tree-grid .tree-icon,
.tree-label {
	cursor : pointer;
}

table.tree-grid {
	max-height : 500px;
	overflow-y : scroll;
}