

main {
	padding-top: 6 * $font-size;
	padding-bottom: 6 * $font-size;
	z-index: 80;
	min-height: 100vh;

	@media #{$mq-xs-max} {
		padding-top: 11 * $font-size;
	}

	.divisao {
		width: 100%;
		height: 40px;
	}

	.titulo-pagina {
		margin: 0;
		margin-bottom: 2 * $font-size;
		padding-bottom: $font-size;
		border-bottom: 1px solid $cor-primaria;
		color: $cor-primaria;
		font-weight: 700;
		text-align: center;
	}
	.titulo-pagina-lancamento {
		margin: 0;
		margin-bottom: 2 * $font-size;
		padding-bottom: $font-size;
		border-bottom: 1px solid $cor-primaria;
		color: $cor-primaria;
		font-weight: 700;
		text-align: left;
	}

	.butom-salvar-lancamento{
		margin-left: 80%;
	}

	.cross-check{
		margin-left: 30%;
	}

	.cross-check-span{
		background-color: red;
	}

	.nome-tab{
		font-weight: 20;
		font-size: 16px;
	}

	.titulo-pagina .subtitulo {
		font-weight: 20;
		font-size: 16px;
	}

	.tabela-sistema {
		margin-bottom: 5px;
	}
}
