$font-size: 14px;

$cor-primaria: #06344c;
$cor-primaria_back: #06344c;
$cor-secundaria: #bdbdbd;
$cor-padrao: #fff;

$mq-xs-min: "only screen and (min-width: 319px)";
$mq-xs-max: "only screen and (max-width: 767px)";

$mq-sm-min: "only screen and (min-width: 768px)";
$mq-sm-max: "only screen and (max-width: 991px)";

$mq-md-min: "only screen and (min-width: 992px)";
$mq-md-max: "only screen and (max-width: 1199px)";

$mq-lg-min: "only screen and (min-width: 992px)";
$mq-lg-max: "only screen and (max-width: 1919px)";

$mq-xl-min: "only screen and (min-width: 1920px)";

$screen-md-min:              992px !default;
$modal-xg:  1200px !default;
