#footer {
	min-height: 38.5px;
	background-color: $cor-primaria_back;
	color: $cor-padrao;

	@media #{$mq-xs-max} {
		font-size: 0.8 * $font-size;
	}
}

.rodape {
	position: absolute;
    bottom: 0;
	left:0;
    width: 100%;
}
