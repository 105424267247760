// font-family: 'Arvo', serif;
// font-family: 'Noto Sans', sans-serif;

body {
  position: relative;
  font-family: 'Noto Sans', sans-serif;
}

textarea {
  resize: none;
}

option:disabled {
  color: #aaa;
  background: #E6E6E6;
}

#conteudo {
  position: relative;
  min-height: 100%;
}

.sem-selecao {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.panel-heading [data-toggle=collapse] {
    margin-top: -15px;
    margin-bottom: -15px;
    padding-top: 15px;
    padding-bottom: 15px;
    cursor: pointer;
}

.aviso-oculto,
.item-oculto {
  display: none;
}

.popover {
  min-width: 200px;

  @media #{$mq-xs-max} {
    //width: 100%;
  }

  .popover-title {
    text-align: center;
  }

  .list-group {
    margin: 0;
  }

  button {
    outline: none;
  }
}

.aviso-404 {
  padding-top: 2 * $font-size;
  font-size: 8 * $font-size;
  color: $cor-primaria;
  font-weight: 700;
}

.sem-margin {
  margin: 0;
  ul, ol, dl {
    margin: 0;
  }
}

.mb-xs {
  margin-bottom: 5px;
}

.mb-sm {
  margin-top: 5px;
  margin-bottom: 10px;
}

.mb-md {
  margin-top: 5px;
  margin-bottom: 20px;
}

.mb-lg {
  margin-top: 10px;
  margin-bottom: 30px;
}

.mb-xl {
  margin-top: 30px;
  margin-bottom: 40px;
}

.texto-dir {
  text-align: right;

  @media #{$mq-sm-max} {
    text-align: center;
  }
}

.texto-esq {
  text-align: left;

  @media #{$mq-sm-max} {
    text-align: center;
  }
}

.texto-primario {
  color: $cor-primaria;
}

.modal-header {
  &.modal-primario {
    background-color: $cor-primaria;
    color: $cor-padrao;
  }
}

.modal-header {
   background-color: $cor-primaria;
   color: $cor-padrao;
}

.ajuda-popover {
  cursor: pointer;
}

#loader {
  z-index: 999999;
  position: fixed;
  width: 100%;
  height: 100%;
  background: rgba(6, 52, 76, 0.57);
}

.sk-circle {
  width: 80px;
  height: 80px;
  left: 50%;
  top: 50%;
  margin-left: -40px;
  margin-top: -40px;
  position: relative;
}

.sk-circle .sk-child {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
}

.sk-circle .sk-child:before {
  content: '';
  display: block;
  margin: 0 auto;
  width: 15%;
  height: 15%;
  background-color: #fff;
  border-radius: 100%;
  -webkit-animation: sk-circleBounceDelay 1.2s infinite ease-in-out both;
  animation: sk-circleBounceDelay 1.2s infinite ease-in-out both;
}

.sk-circle .sk-circle2 {
  -webkit-transform: rotate(30deg);
  -ms-transform: rotate(30deg);
  transform: rotate(30deg);
}

.sk-circle .sk-circle3 {
  -webkit-transform: rotate(60deg);
  -ms-transform: rotate(60deg);
  transform: rotate(60deg);
}

.sk-circle .sk-circle4 {
  -webkit-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  transform: rotate(90deg);
}

.sk-circle .sk-circle5 {
  -webkit-transform: rotate(120deg);
  -ms-transform: rotate(120deg);
  transform: rotate(120deg);
}

.sk-circle .sk-circle6 {
  -webkit-transform: rotate(150deg);
  -ms-transform: rotate(150deg);
  transform: rotate(150deg);
}

.sk-circle .sk-circle7 {
  -webkit-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  transform: rotate(180deg);
}

.sk-circle .sk-circle8 {
  -webkit-transform: rotate(210deg);
  -ms-transform: rotate(210deg);
  transform: rotate(210deg);
}

.sk-circle .sk-circle9 {
  -webkit-transform: rotate(240deg);
  -ms-transform: rotate(240deg);
  transform: rotate(240deg);
}

.sk-circle .sk-circle10 {
  -webkit-transform: rotate(270deg);
  -ms-transform: rotate(270deg);
  transform: rotate(270deg);
}

.sk-circle .sk-circle11 {
  -webkit-transform: rotate(300deg);
  -ms-transform: rotate(300deg);
  transform: rotate(300deg);
}

.sk-circle .sk-circle12 {
  -webkit-transform: rotate(330deg);
  -ms-transform: rotate(330deg);
  transform: rotate(330deg);
}

.sk-circle .sk-circle2:before {
  -webkit-animation-delay: -1.1s;
  animation-delay: -1.1s;
}

.sk-circle .sk-circle3:before {
  -webkit-animation-delay: -1s;
  animation-delay: -1s;
}

.sk-circle .sk-circle4:before {
  -webkit-animation-delay: -0.9s;
  animation-delay: -0.9s;
}

.sk-circle .sk-circle5:before {
  -webkit-animation-delay: -0.8s;
  animation-delay: -0.8s;
}

.sk-circle .sk-circle6:before {
  -webkit-animation-delay: -0.7s;
  animation-delay: -0.7s;
}

.sk-circle .sk-circle7:before {
  -webkit-animation-delay: -0.6s;
  animation-delay: -0.6s;
}

.sk-circle .sk-circle8:before {
  -webkit-animation-delay: -0.5s;
  animation-delay: -0.5s;
}

.sk-circle .sk-circle9:before {
  -webkit-animation-delay: -0.4s;
  animation-delay: -0.4s;
}

.sk-circle .sk-circle10:before {
  -webkit-animation-delay: -0.3s;
  animation-delay: -0.3s;
}

.sk-circle .sk-circle11:before {
  -webkit-animation-delay: -0.2s;
  animation-delay: -0.2s;
}

.sk-circle .sk-circle12:before {
  -webkit-animation-delay: -0.1s;
  animation-delay: -0.1s;
}

@-webkit-keyframes sk-circleBounceDelay {
  0%, 80%, 100% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  40% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

@keyframes sk-circleBounceDelay {
  0%, 80%, 100% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  40% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

.transition {
  transition: all 0.5s;
  -moz-transition: all 0.5s; /* Firefox 4 */
  -webkit-transition: all 0.5s; /* Safari and Chrome */
  -o-transition: all 0.5s; /* Opera */
  -ms-transition:all 0.5s; /* IE9 (maybe) */
}

.shadow:hover {
  box-shadow: 0px 0 15px 6px #2E2F2E;
  transform: scale(1.009);
}

.row-eq-height {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display:         flex;
}

.rdias-blue,
.ardays-navy,
.inpipou-navy,
.emprocesso-navy,
.ricardo-dias-navy,
.rogerio-dias-navy,
.ribeirator-days-navy {
  background-color:#06344c;
  color:#fff;
}

.text-vcenter {
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.fav-ramo-row {
  transition:.4s ease;
  cursor:pointer;
}

.fav-ramo-row:hover {
  background:#06344c;
  color:#FFF;
  opacity:0.8;
  transition:.05s ease;
}

.back-to-top {
    cursor: pointer;
    position: fixed;
    bottom: 60px;
    right: 20px;
    display:none;
    z-index: 9999999999 !important;}

.help-pagina:hover{
  transform: scale(1.5);
}

.alert-header-danger{
  background-color:#d9534f;
  color:#fff;
}

.alinha-vertical{
  vertical-align: middle !important;
}

.cursor_link{
    cursor: pointer;
  }

.altura-pdf {
  height: 700px !important;

  @media #{$mq-sm-max} {
   height: 200px !important;
  }

  @media #{$mq-md-max} {
   height: 400px !important;
  }
}
/* modificação do bootstrap-select */
.bootstrap-select.btn-group .dropdown-menu li a:hover {
  background-color:lightgray !important;
  color: black !important;
}

.bootstrap-select.btn-group.show-tick .dropdown-menu li.selected a{
  background-color: #d9edf7 !important;
  color: black !important;
}

@media (min-width: $screen-md-min) {
  .modal-xg { width: $modal-xg; }
}

//.ng-cloak { display: none !important; }
[ng\:cloak], [ng-cloak], [data-ng-cloak], [x-ng-cloak], .ng-cloak, .x-ng-cloak {
  display: none !important;
}

.navy-outline {
  color:white;
  text-shadow:0px 1px 0px #104e67,
              1px 0px 0px #104e67,
              0px -1px 0px #104e67,
              -1px 0px 0px #104e67,

              0px 2px 0px #104e67,
              2px 0px 0px #104e67,
              0px -2px 0px #104e67,
              -2px 0px 0px #104e67,

              1px 1px 0px #104e67,
              -1px 1px 0px #104e67,
              1px -1px 0px #104e67,
              -1px 1px 0px #104e67;
}
.addon-navy-button {
    background: #06344c;
    color: white;
    border-color: #042535;
}

.popover-title {
  background-color:#06344C !important;
  color:#fff !important;
}
.minhas_tabs > ul > li > a{
    background-color: #06344c;
    color: #fff;
    border-top: 1px solid #00ff00;
    border-right: 1px solid #00ff00;
    border-left: 1px solid #00ff00;
}
.minhas_tabs > ul > li > a:hover {
    color: #000;
}
.erro_tab{
    background-color: #fcb9b7 !important;
    color: #000 !important;
    border-top: 1px solid #fff !important;
    border-right: 1px solid #fff !important;
    border-left: 1px solid #fff !important;
  }
  .close{
        color: #fff !important;
        opacity: 2 !important;
           
    }
.edicao {
    background-color: #fff3b0 !important;
}
.invalidInput{
    border: 1px solid #ff0000;
}

.deletarAusencia{
    background:#fcb9b7 !important;
}

@media #{$mq-xs-max} {
    .btn-xs-block{
      width: 100%;
    }
  }

  /* ==========================================================================
   Estilos para impressão
   ========================================================================== */

@media print {

    * {
        background: transparent !important;
        color: #000 !important;
        text-shadow: none !important;
        filter:none !important;
        -ms-filter: none !important;
    }

    body {
        margin:0;
        padding:0;
        line-height: 1.4em;
        font: 12pt Georgia, "Times New Roman", Times, serif;
        color: #000;
    }

    @page {
        margin: 1.5cm;
    }

    .wrap {
        width: 100%;
        margin: 0;
        float: none !important;
    }

    .no-print, nav, footer, video, audio, object, embed {
        display:none;
    }

    .print {
        display: block;
    }


}

.btn-outline {
    background-color: transparent;
    color: inherit;
}

.btn-primary.btn-outline {
    color: #104e67;
}

.btn-success.btn-outline {
    color: #5cb85c;
}

.btn-info.btn-outline {
    color: #5bc0de;
}

.btn-warning.btn-outline {
    color: #f0ad4e;
}

.btn-danger.btn-outline {
    color: #d9534f;
}

.btn-primary.btn-outline:hover,
.btn-success.btn-outline:hover,
.btn-info.btn-outline:hover,
.btn-warning.btn-outline:hover,
.btn-danger.btn-outline:hover {
    color: #fff;
}


.box-container {
	  position: relative;
}
.box-container:before {
  	content: "";
  	display: block;
  	padding-top: 100%;
}
.box-container .box-inner {
  	box-sizing:border-box;
  	position:  absolute;
  	top: 0; left: 0; bottom: 0; right: 0;
}
