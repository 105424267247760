
 .et-pb-row-content {
   padding: 0;
 }
.editando .et-pb-row-content {
  padding: 15px 15px 11px 65px;
}

.ui-state-highlight {
    height: 10em; line-height: 10em;
  }
.ajustavel-placeholder {
    border: 1px dotted black;
    margin: 0 1em 1em 0;
    height: 50px;
    width: 100%;
  }
.ajustavel {
    padding-bottom: 50px;
    /*border: 1px dotted black;*/
    /*height: 1%;*/
    /*background: linear-gradient(180deg, #FFC0CB 59%, #FFFFFF 50%);*/
    /*margin: 0 1em 1em 0;*/
    /*min-height: 50px;*/
  }
.et_pb_module_block{
    cursor: default !important;
    background-color: #FCFCFC !important;
    border-radius: 10px !important;
}
.et-pb-controls{
    cursor:default !important;
}
.conteudo-painel{
    // padding: 30px;
}

.et_pb_row{
    background: #FFFFFF !important;
}

.bloco_vazio{
    background-color: #D8E0E3 !important;
    height: 50px;
}
.botao_remover {
    line-height:normal;
    display: inline-block;
    vertical-align:bottom;
    cursor:pointer !important;
}

.invisivel {
    display: none;
    transition: opacity 1s ease-out;
    opacity: 0;
}

.bubble
{
    padding: 10px;
    /*background-color: #fafcfd;*/
    background-color: #F0F5F7;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    border-radius: 10px;
}
#menu_dash {
    padding-right: 29px;
}

.ponta_balao
{
    content: '';
    position: absolute;
    border-style: solid;
    border-width: 15px 0 15px 15px;
    border-color: transparent #F0F5F7;
    display: block;
    /*width: 0;*/
    /*z-index: 1;*/
    right: 100px;
    top: 122px;
}

.g_tam1{
    height:16em;
}

.g_tam2{
    height:24em;
}

.g_tam3{
    height:30em;
}

.g_tam4{
    height:36em;
}

.g_tam5{
    height:42em;
}

.grafico1{
    // margin: auto;
}

.hand{
    cursor:pointer;
}

.lista-painel-placeholder{
    height:1.5em;
    line-height:1.2em;
}

.espacamentoOrdPainel{
    margin-bottom: 8px;
}

#sortablePainel{
    list-style-type: none;
}

.item-padrao{
    background-color: #D6E4ED;
}
.cont_combinado{
    min-height: 20px;
    padding: 19px;
    margin-bottom: 20px;
    background-color: #F9FCFE;
    border: 1px solid #e3e3e3;
    border-radius: 3px;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.05);
}
.formError{
    display: none;
    margin-top: 0px;
    padding: 3px;
    padding-left: 8px;
    font-weight: bold;
    border: 2px solid #FFA7A7;
    border-top: 0px;
}
