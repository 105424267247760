@import "base/variaveis";
@import "base/mixins";
@import "base/menu";
@import "base/geral";
@import "base/header";
@import "base/main";
@import "base/footer";
@import "base/analise_rp";

.popover {
  max-width: 400px;
}

.tooltip-inner  {
    white-space:pre;
    max-width:none;
}

td {
    position:relative;
}

.menu-usuario {
  .modal-dialog {
    width:380px;
  }
  .modal-body {
    position: relative;

    .md-attr-email-usuario {
        text-overflow: ellipsis;
        overflow: hidden;
        width: 100%;
        display: inline-block;
        white-space: nowrap;

        .md-attr-email-usuario-overflow {
            border-bottom: 1px dotted black;
            cursor: help;
        }
    }

    .letter-box {
      position: relative;
      width: 40%;
      float: left;
      padding: 15px;

      .box-container {
        position: relative;
        background: #06344C;
        border-radius: 50%;
        text-align: center;

        span {
          position: relative;
          color: #fff;
          font-size: 77px;
          text-transform: uppercase;
        }

        &:before {
          padding-top: 0;
        }
      }
    }
    .info-box {
      position: relative;
      float: left;
      width: 60%;

      .box-container {
        position: relative;
        padding: 10px;

        .infos {
          position: relative;

          span {
            position: relative;
          }
        }
        .buttons {
          position: relative;
          margin: 20px 0 0 0;
        }

        &:before {
          padding-top: 0;
        }
      }
    }
  }
  .modal-footer {
    position: relative;
    background: #D6D6D6;
    border-top: 1px solid #B9B1B1;
    border-radius: 0px 0px 4px 4px;
    padding: 10px;
  }
}


/* Plugin styles */
ul.thumbnails-carousel {
  padding: 5px 0 0 0;
  margin: 0;
  list-style-type: none;
  text-align: center;

  .center {
    display: inline-block;
  }

  li {
    margin-right: 5px;
    float: left;
    cursor: pointer;
  }

}
.controls-background-reset {
  background: none !important;
}
.active-thumbnail {
  opacity: 0.4;
}
.indicators-fix {
  bottom: 70px;
}



.fav-ramo-row {
  transition: .4s ease;
  cursor: pointer;
}
.fav-ramo-row.active {
  border-color:#FFF;
}

.fav-ramo-row:hover {
  background: #06344c;
  color: #FFF;
  opacity: 0.8;
  transition: .05s ease; }

.fav-ramo-row .panel-title{
  margin:15px 0;
}

.div-pivot {
  max-height: 60vh;
}


.modal.in {
	overflow-x: hidden;
    overflow-y: auto;
}

.table-rotina {
  .warning td {
    background-color: #FBBC05!important;
    &:hover {
      background-color: darken(#FBBC05, 10%)!important;
    }
  }
}

.panel-body.panel-body-fluid {
    padding: 0;

    .list-group {
        margin: 0;
    }
}
