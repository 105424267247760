@charset "UTF-8";
.nav-side-menu {
  overflow: auto;
  background-color: #06344c;
  position: fixed;
  top: 50px;
  width: 350px;
  height: 100%;
  color: #fff;
  z-index: 300;
  display: none; }
  .nav-side-menu #menus-nav {
    margin-bottom: 200px; }
  @media only screen and (max-width: 767px) {
    .nav-side-menu {
      position: fixed;
      height: auto;
      max-height: 86vh;
      top: 50px;
      width: 100%;
      margin-bottom: 10px;
      display: block !important;
      padding-bottom: 0; } }
  .nav-side-menu .brand {
    background-color: #02141d;
    line-height: 42px;
    display: block;
    text-align: left;
    font-size: 16px;
    font-weight: 700; }
    .nav-side-menu .brand a {
      color: #fff; }
  .nav-side-menu .toggle-btn {
    display: none; }
    @media only screen and (max-width: 767px) {
      .nav-side-menu .toggle-btn {
        position: absolute;
        display: block;
        cursor: pointer;
        right: 10px;
        top: 10px;
        z-index: 10;
        padding: 3px;
        background-color: #fff;
        color: #000;
        width: 40px;
        text-align: center; } }
  .nav-side-menu ul, .nav-side-menu li {
    list-style: none;
    padding: 0px;
    margin: 0px;
    line-height: 35px;
    cursor: pointer; }
    .nav-side-menu ul .sub-menu li, .nav-side-menu li .sub-menu li {
      border: none;
      line-height: 28px;
      margin-left: 0px; }
  .nav-side-menu ul li {
    transition: all 0.6s ease; }
    .nav-side-menu ul li:before {
      display: inline-block;
      padding-left: 10px;
      padding-right: 10px;
      vertical-align: middle; }
    .nav-side-menu ul li a {
      width: 100%;
      text-decoration: none;
      color: #fff;
      display: block; }
      .nav-side-menu ul li a i {
        width: 20px;
        padding-left: 10px;
        padding-right: 20px; }
    .nav-side-menu ul li:hover {
      background-color: #8a8a8a;
      transition: all 0.05s ease; }
  .nav-side-menu ul .sub-menu.n-1 li {
    padding-left: 20px;
    background-color: #084464; }
    .nav-side-menu ul .sub-menu.n-1 li i {
      padding-left: 0; }
  .nav-side-menu ul .sub-menu.n-2 li {
    padding-left: 40px;
    background-color: #0a547b; }
    .nav-side-menu ul .sub-menu.n-2 li i {
      padding-left: 0; }
  .nav-side-menu ul .sub-menu.n-3 li {
    padding-left: 60px;
    background-color: #0a547b; }
    .nav-side-menu ul .sub-menu.n-3 li i {
      padding-left: 0; }
  .nav-side-menu ul .sub-menu.n-4 li {
    padding-left: 80px;
    background-color: #0a547b; }
    .nav-side-menu ul .sub-menu.n-4 li i {
      padding-left: 0; }
  .nav-side-menu ul .sub-menu li {
    transition: all 0.6s ease; }
    .nav-side-menu ul .sub-menu li:hover {
      background-color: #8a8a8a;
      transition: all 0.05s ease; }
  .nav-side-menu .tem-sub {
    font-weight: 700; }
    .nav-side-menu .tem-sub a {
      color: #bde5fa; }
    .nav-side-menu .tem-sub.collapsed:before {
      float: right;
      font-family: FontAwesome;
      content: "\f0ab"; }
    .nav-side-menu .tem-sub:before {
      float: right;
      font-family: FontAwesome;
      content: "\f0aa"; }

@media only screen and (max-width: 767px) {
  .nav-side-menu > .brand {
    text-align: left;
    font-size: 14px;
    padding-left: 20px;
    line-height: 35px;
    background-color: #bdbdbd;
    height: 51px;
    overflow: hidden; }
    .nav-side-menu > .brand .brand-breadcrumbs {
      line-height: 51px;
      overflow: overlay;
      height: 80px; }
      .nav-side-menu > .brand .brand-breadcrumbs .breadcrumb {
        padding: 0;
        margin: 0;
        background: none;
        border-radius: 0;
        white-space: nowrap; }
        .nav-side-menu > .brand .brand-breadcrumbs .breadcrumb .active {
          color: white;
          font-size: 13px; }
        .nav-side-menu > .brand .brand-breadcrumbs .breadcrumb li:last-child {
          margin-right: 150px; } }

@media only screen and (max-width: 767px) {
  .menu-normal {
    display: none; } }

@media only screen and (min-width: 768px) {
  .nav-side-menu .menu-list .menu-content {
    display: block; } }

body {
  position: relative;
  font-family: 'Noto Sans', sans-serif; }

textarea {
  resize: none; }

option:disabled {
  color: #aaa;
  background: #E6E6E6; }

#conteudo {
  position: relative;
  min-height: 100%; }

.sem-selecao {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -ms-user-select: none;
  user-select: none; }

.panel-heading [data-toggle=collapse] {
  margin-top: -15px;
  margin-bottom: -15px;
  padding-top: 15px;
  padding-bottom: 15px;
  cursor: pointer; }

.aviso-oculto,
.item-oculto {
  display: none; }

.popover {
  min-width: 200px; }
  .popover .popover-title {
    text-align: center; }
  .popover .list-group {
    margin: 0; }
  .popover button {
    outline: none; }

.aviso-404 {
  padding-top: 28px;
  font-size: 112px;
  color: #06344c;
  font-weight: 700; }

.sem-margin {
  margin: 0; }
  .sem-margin ul, .sem-margin ol, .sem-margin dl {
    margin: 0; }

.mb-xs {
  margin-bottom: 5px; }

.mb-sm {
  margin-top: 5px;
  margin-bottom: 10px; }

.mb-md {
  margin-top: 5px;
  margin-bottom: 20px; }

.mb-lg {
  margin-top: 10px;
  margin-bottom: 30px; }

.mb-xl {
  margin-top: 30px;
  margin-bottom: 40px; }

.texto-dir {
  text-align: right; }
  @media only screen and (max-width: 991px) {
    .texto-dir {
      text-align: center; } }

.texto-esq {
  text-align: left; }
  @media only screen and (max-width: 991px) {
    .texto-esq {
      text-align: center; } }

.texto-primario {
  color: #06344c; }

.modal-header.modal-primario {
  background-color: #06344c;
  color: #fff; }

.modal-header {
  background-color: #06344c;
  color: #fff; }

.ajuda-popover {
  cursor: pointer; }

#loader {
  z-index: 999999;
  position: fixed;
  width: 100%;
  height: 100%;
  background: rgba(6, 52, 76, 0.57); }

.sk-circle {
  width: 80px;
  height: 80px;
  left: 50%;
  top: 50%;
  margin-left: -40px;
  margin-top: -40px;
  position: relative; }

.sk-circle .sk-child {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0; }

.sk-circle .sk-child:before {
  content: '';
  display: block;
  margin: 0 auto;
  width: 15%;
  height: 15%;
  background-color: #fff;
  border-radius: 100%;
  animation: sk-circleBounceDelay 1.2s infinite ease-in-out both; }

.sk-circle .sk-circle2 {
  transform: rotate(30deg); }

.sk-circle .sk-circle3 {
  transform: rotate(60deg); }

.sk-circle .sk-circle4 {
  transform: rotate(90deg); }

.sk-circle .sk-circle5 {
  transform: rotate(120deg); }

.sk-circle .sk-circle6 {
  transform: rotate(150deg); }

.sk-circle .sk-circle7 {
  transform: rotate(180deg); }

.sk-circle .sk-circle8 {
  transform: rotate(210deg); }

.sk-circle .sk-circle9 {
  transform: rotate(240deg); }

.sk-circle .sk-circle10 {
  transform: rotate(270deg); }

.sk-circle .sk-circle11 {
  transform: rotate(300deg); }

.sk-circle .sk-circle12 {
  transform: rotate(330deg); }

.sk-circle .sk-circle2:before {
  animation-delay: -1.1s; }

.sk-circle .sk-circle3:before {
  animation-delay: -1s; }

.sk-circle .sk-circle4:before {
  animation-delay: -0.9s; }

.sk-circle .sk-circle5:before {
  animation-delay: -0.8s; }

.sk-circle .sk-circle6:before {
  animation-delay: -0.7s; }

.sk-circle .sk-circle7:before {
  animation-delay: -0.6s; }

.sk-circle .sk-circle8:before {
  animation-delay: -0.5s; }

.sk-circle .sk-circle9:before {
  animation-delay: -0.4s; }

.sk-circle .sk-circle10:before {
  animation-delay: -0.3s; }

.sk-circle .sk-circle11:before {
  animation-delay: -0.2s; }

.sk-circle .sk-circle12:before {
  animation-delay: -0.1s; }

@keyframes sk-circleBounceDelay {
  0%, 80%, 100% {
    transform: scale(0); }
  40% {
    transform: scale(1); } }

.transition {
  transition: all 0.5s;
  -moz-transition: all 0.5s;
  /* Firefox 4 */
  -webkit-transition: all 0.5s;
  /* Safari and Chrome */
  -o-transition: all 0.5s;
  /* Opera */
  -ms-transition: all 0.5s;
  /* IE9 (maybe) */ }

.shadow:hover {
  box-shadow: 0px 0 15px 6px #2E2F2E;
  transform: scale(1.009); }

.row-eq-height {
  display: -ms-flexbox;
  display: flex; }

.rdias-blue,
.ardays-navy,
.inpipou-navy,
.emprocesso-navy,
.ricardo-dias-navy,
.rogerio-dias-navy,
.ribeirator-days-navy {
  background-color: #06344c;
  color: #fff; }

.text-vcenter {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
  justify-content: center;
  -ms-flex-direction: column;
  flex-direction: column; }

.fav-ramo-row {
  transition: .4s ease;
  cursor: pointer; }

.fav-ramo-row:hover {
  background: #06344c;
  color: #FFF;
  opacity: 0.8;
  transition: .05s ease; }

.back-to-top {
  cursor: pointer;
  position: fixed;
  bottom: 60px;
  right: 20px;
  display: none;
  z-index: 9999999999 !important; }

.help-pagina:hover {
  transform: scale(1.5); }

.alert-header-danger {
  background-color: #d9534f;
  color: #fff; }

.alinha-vertical {
  vertical-align: middle !important; }

.cursor_link {
  cursor: pointer; }

.altura-pdf {
  height: 700px !important; }
  @media only screen and (max-width: 991px) {
    .altura-pdf {
      height: 200px !important; } }
  @media only screen and (max-width: 1199px) {
    .altura-pdf {
      height: 400px !important; } }

/* modificação do bootstrap-select */
.bootstrap-select.btn-group .dropdown-menu li a:hover {
  background-color: lightgray !important;
  color: black !important; }

.bootstrap-select.btn-group.show-tick .dropdown-menu li.selected a {
  background-color: #d9edf7 !important;
  color: black !important; }

@media (min-width: 992px) {
  .modal-xg {
    width: 1200px; } }

[ng\:cloak], [ng-cloak], [data-ng-cloak], [x-ng-cloak], .ng-cloak, .x-ng-cloak {
  display: none !important; }

.navy-outline {
  color: white;
  text-shadow: 0px 1px 0px #104e67, 1px 0px 0px #104e67, 0px -1px 0px #104e67, -1px 0px 0px #104e67,  0px 2px 0px #104e67, 2px 0px 0px #104e67, 0px -2px 0px #104e67, -2px 0px 0px #104e67,  1px 1px 0px #104e67, -1px 1px 0px #104e67, 1px -1px 0px #104e67, -1px 1px 0px #104e67; }

.addon-navy-button {
  background: #06344c;
  color: white;
  border-color: #042535; }

.popover-title {
  background-color: #06344C !important;
  color: #fff !important; }

.minhas_tabs > ul > li > a {
  background-color: #06344c;
  color: #fff;
  border-top: 1px solid #00ff00;
  border-right: 1px solid #00ff00;
  border-left: 1px solid #00ff00; }

.minhas_tabs > ul > li > a:hover {
  color: #000; }

.erro_tab {
  background-color: #fcb9b7 !important;
  color: #000 !important;
  border-top: 1px solid #fff !important;
  border-right: 1px solid #fff !important;
  border-left: 1px solid #fff !important; }

.close {
  color: #fff !important;
  opacity: 2 !important; }

.edicao {
  background-color: #fff3b0 !important; }

.invalidInput {
  border: 1px solid #ff0000; }

.deletarAusencia {
  background: #fcb9b7 !important; }

@media only screen and (max-width: 767px) {
  .btn-xs-block {
    width: 100%; } }

/* ==========================================================================
   Estilos para impressão
   ========================================================================== */
@media print {
  * {
    background: transparent !important;
    color: #000 !important;
    text-shadow: none !important;
    filter: none !important;
    -ms-filter: none !important; }
  body {
    margin: 0;
    padding: 0;
    line-height: 1.4em;
    font: 12pt Georgia, "Times New Roman", Times, serif;
    color: #000; }
  @page {
    margin: 1.5cm; }
  .wrap {
    width: 100%;
    margin: 0;
    float: none !important; }
  .no-print, nav, footer, video, audio, object, embed {
    display: none; }
  .print {
    display: block; } }

.btn-outline {
  background-color: transparent;
  color: inherit; }

.btn-primary.btn-outline {
  color: #104e67; }

.btn-success.btn-outline {
  color: #5cb85c; }

.btn-info.btn-outline {
  color: #5bc0de; }

.btn-warning.btn-outline {
  color: #f0ad4e; }

.btn-danger.btn-outline {
  color: #d9534f; }

.btn-primary.btn-outline:hover,
.btn-success.btn-outline:hover,
.btn-info.btn-outline:hover,
.btn-warning.btn-outline:hover,
.btn-danger.btn-outline:hover {
  color: #fff; }

.box-container {
  position: relative; }

.box-container:before {
  content: "";
  display: block;
  padding-top: 100%; }

.box-container .box-inner {
  box-sizing: border-box;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0; }

#header {
  position: fixed;
  width: 100%;
  height: 50px;
  background-color: #06344c;
  z-index: 200; }
  #header #box-menu {
    position: relative;
    background-color: #bdbdbd;
    margin: 0;
    padding: 0;
    text-align: center; }
    #header #box-menu i {
      padding: 0;
      line-height: 50px;
      height: 50px;
      font-size: 28px;
      color: #fff;
      display: block;
      cursor: pointer; }
      #header #box-menu i:hover {
        color: #06344c; }
  #header .logo-aplicativo-header {
    height: 70px;
    margin: 7px;
    border-left-width: thin;
    border-left-color: white;
    border-left-style: groove; }
    @media only screen and (max-width: 991px) {
      #header .logo-aplicativo-header {
        margin: 7px 3px; } }
  #header .logo-empresa-header-link {
    height: 50px;
    display: inline-block; }
  #header .logo-empresa-header {
    height: 50px;
    margin-right: 15px; }
  #header ol.breadcrumb {
    list-style: none;
    margin: 3px;
    padding: 14px;
    border-radius: 5px;
    background-color: transparent;
    font-size: 11.2px; }
    #header ol.breadcrumb li {
      display: inline;
      color: #fff; }
      #header ol.breadcrumb li a {
        color: #fff; }
      #header ol.breadcrumb li:last-child {
        color: #aaa; }
        #header ol.breadcrumb li:last-child::after {
          content: '';
          padding: 0; }
  #header .menu-icone-header {
    list-style: none;
    margin: 0px; }
    #header .menu-icone-header li {
      display: inline-block;
      width: 50px;
      text-align: center;
      padding: 0 0;
      margin-left: -4px; }
      #header .menu-icone-header li a, #header .menu-icone-header li i {
        color: #fff; }
      #header .menu-icone-header li i {
        font-size: 28px;
        cursor: pointer;
        padding: 11.2px 0;
        width: 100%; }
        #header .menu-icone-header li i:hover {
          background: rgba(0, 0, 0, 0.4); }

.barra-pesquisa {
  position: absolute;
  top: 50px;
  width: 100%;
  height: 40px;
  background-color: #bdbdbd;
  display: none;
  z-index: 500; }
  .barra-pesquisa .sem-padding {
    padding: 0; }
  .barra-pesquisa input {
    width: 100%;
    height: 40px;
    font-size: 18.2px;
    text-align: center;
    border: none;
    outline: none;
    background-color: #bdbdbd; }
  .barra-pesquisa ::-webkit-input-placeholder {
    color: #333; }
  .barra-pesquisa :-moz-placeholder {
    color: #333; }
  .barra-pesquisa ::-moz-placeholder {
    color: #333; }
  .barra-pesquisa :-ms-input-placeholder {
    color: #333; }
  .barra-pesquisa .btn-fechar {
    width: 100%;
    height: 40px;
    margin: 0;
    padding: 0;
    background-color: #C9302C;
    border: none;
    color: #fff;
    font-size: 28px; }
    .barra-pesquisa .btn-fechar i {
      line-height: 40px; }

.menu-plus {
  margin: 17px 0;
  cursor: pointer;
  display: block;
  color: #fff; }
  .menu-plus i {
    font-size: 42px; }
  .menu-plus:hover {
    color: #bdbdbd; }

main {
  padding-top: 84px;
  padding-bottom: 84px;
  z-index: 80;
  min-height: 100vh; }
  @media only screen and (max-width: 767px) {
    main {
      padding-top: 154px; } }
  main .divisao {
    width: 100%;
    height: 40px; }
  main .titulo-pagina {
    margin: 0;
    margin-bottom: 28px;
    padding-bottom: 14px;
    border-bottom: 1px solid #06344c;
    color: #06344c;
    font-weight: 700;
    text-align: center; }
  main .titulo-pagina-lancamento {
    margin: 0;
    margin-bottom: 28px;
    padding-bottom: 14px;
    border-bottom: 1px solid #06344c;
    color: #06344c;
    font-weight: 700;
    text-align: left; }
  main .butom-salvar-lancamento {
    margin-left: 80%; }
  main .cross-check {
    margin-left: 30%; }
  main .cross-check-span {
    background-color: red; }
  main .nome-tab {
    font-weight: 20;
    font-size: 16px; }
  main .titulo-pagina .subtitulo {
    font-weight: 20;
    font-size: 16px; }
  main .tabela-sistema {
    margin-bottom: 5px; }

#footer {
  min-height: 38.5px;
  background-color: #06344c;
  color: #fff; }
  @media only screen and (max-width: 767px) {
    #footer {
      font-size: 11.2px; } }

.rodape {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%; }

.popover {
  max-width: 400px; }

.tooltip-inner {
  white-space: pre;
  max-width: none; }

td {
  position: relative; }

.menu-usuario .modal-dialog {
  width: 380px; }

.menu-usuario .modal-body {
  position: relative; }
  .menu-usuario .modal-body .md-attr-email-usuario {
    text-overflow: ellipsis;
    overflow: hidden;
    width: 100%;
    display: inline-block;
    white-space: nowrap; }
    .menu-usuario .modal-body .md-attr-email-usuario .md-attr-email-usuario-overflow {
      border-bottom: 1px dotted black;
      cursor: help; }
  .menu-usuario .modal-body .letter-box {
    position: relative;
    width: 40%;
    float: left;
    padding: 15px; }
    .menu-usuario .modal-body .letter-box .box-container {
      position: relative;
      background: #06344C;
      border-radius: 50%;
      text-align: center; }
      .menu-usuario .modal-body .letter-box .box-container span {
        position: relative;
        color: #fff;
        font-size: 77px;
        text-transform: uppercase; }
      .menu-usuario .modal-body .letter-box .box-container:before {
        padding-top: 0; }
  .menu-usuario .modal-body .info-box {
    position: relative;
    float: left;
    width: 60%; }
    .menu-usuario .modal-body .info-box .box-container {
      position: relative;
      padding: 10px; }
      .menu-usuario .modal-body .info-box .box-container .infos {
        position: relative; }
        .menu-usuario .modal-body .info-box .box-container .infos span {
          position: relative; }
      .menu-usuario .modal-body .info-box .box-container .buttons {
        position: relative;
        margin: 20px 0 0 0; }
      .menu-usuario .modal-body .info-box .box-container:before {
        padding-top: 0; }

.menu-usuario .modal-footer {
  position: relative;
  background: #D6D6D6;
  border-top: 1px solid #B9B1B1;
  border-radius: 0px 0px 4px 4px;
  padding: 10px; }

/* Plugin styles */
ul.thumbnails-carousel {
  padding: 5px 0 0 0;
  margin: 0;
  list-style-type: none;
  text-align: center; }
  ul.thumbnails-carousel .center {
    display: inline-block; }
  ul.thumbnails-carousel li {
    margin-right: 5px;
    float: left;
    cursor: pointer; }

.controls-background-reset {
  background: none !important; }

.active-thumbnail {
  opacity: 0.4; }

.indicators-fix {
  bottom: 70px; }

.fav-ramo-row {
  transition: .4s ease;
  cursor: pointer; }

.fav-ramo-row.active {
  border-color: #FFF; }

.fav-ramo-row:hover {
  background: #06344c;
  color: #FFF;
  opacity: 0.8;
  transition: .05s ease; }

.fav-ramo-row .panel-title {
  margin: 15px 0; }

.div-pivot {
  max-height: 60vh; }

.modal.in {
  overflow-x: hidden;
  overflow-y: auto; }

.table-rotina .warning td {
  background-color: #FBBC05 !important; }
  .table-rotina .warning td:hover {
    background-color: #ca9703 !important; }

.panel-body.panel-body-fluid {
  padding: 0; }
  .panel-body.panel-body-fluid .list-group {
    margin: 0; }

/*# sourceMappingURL=app.css.map */
